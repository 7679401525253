.App {
  text-align: center;
  background-color: gainsboro;
}

.App-logo {
  height: 60vmin;
}

body {
  background-color: gainsboro;
}
.App-header {
  background-color: #282c34;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #3d8f6d;
}

.AppBar {
  background-color: #3d8f6d;
  padding-top: 10px;
}

.Tabs {
  font-size: 20px;
  font-weight: 700;
}
.TitleBar {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  min-height: 2vh;
}

.nav {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.wrap {
  margin: 40;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: whitesmoke;
}

.wrapChecks {
  margin: 20px auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  background-color: whitesmoke;
}

.Heading {
  padding-top: 20px;
  padding-bottom: 5;
}

.Row {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.column {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

h3 {
  margin: 0 auto;
}

.HomeBtn {
  text-decoration: none;
  color: white;
}

.PrimaryBtn {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  margin-bottom: 20px;
  font-size: 2vh;
  font-family: quicksand;
  color: white;
  padding: 8px;
  width: 50%;
  border-radius: 0.5vh;
  background-color: #3d8f6d;
  border: none;
}

.FilterBtn {
  background-color: #3d8f6d;
  width: 100%;
  margin: 0 auto;
}

.Filter {
  font-size: 15px;
  justify-content: end;
}

.Form {
  margin: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  align-items: center;
  background-color: whitesmoke;
  border-radius: 5px;
}

.FormCont {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  min-width: 500px;
  padding: 30px;
  align-items: center;
  background-color: gainsboro;
  /* background-color: rgba(100, 210, 149, 0.851); */
  border-radius: 5px;
}

.Input {
  display: flex;
  justify-content: center;
  margin: 10px auto;
  margin-bottom: 20px;
  height: 35px;
  width: 90%;
  max-width: 600px;
  border-radius: 0.5vh;
  border: 1px solid lightgray;
}

.label {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;

  font-size: 20px;
}
.TreatForm {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  min-width: 450px;
}

.TreatInput {
  margin: 0 auto;
  padding: 10px;
  width: 100%;
  border-radius: 0.5vh;
  border: 1px solid lightgray;
}
.treatments {
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-width: max-content;
  width: 100%;
  border: 1px solid lightgrey;
}

h2 {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  padding-bottom: 30px;
}

.card {
  max-width: 350px;
  margin: 20px;
  height: "";
}
.content {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.label {
  font-weight: 700;
  margin-right: 10px;
}
.title {
  font-weight: 700;
  padding: 10px;
}
.row {
  display: flex;
  flex-direction: row;
  margin: auto, 10px;
}

.loader {
  margin: 0 auto;
  padding: 40px;
}
